<template>
    <tbody class="total-group info">
        <tr class="width-hack">
            <th class="col-fixed"></th>
            <th></th>
            <th class="col-fixed"></th>
            <th class="col-fixed" v-if="props.mostrar_pico == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_pico == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_pico == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_mdp == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_mdp == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_mdp == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_ba == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_ba == 1"></th>
            <th class="col-fixed" v-if="props.mostrar_ba == 1"></th>
        </tr>
        <tr class="fila-total" ref="rowTotals">
            <th class="info table table-bordered table-responsive padding-0" colspan="3" rowspan="3">
                <table id="tabla-totales" v-show="props.showTotals">
                    <tr>
                        <td colspan="7">
                            Meses a analizar:
                            <strong class="meses-a-analizar"> {{ roundMonths(props.meses_a_analizar) }} </strong> 
                            - Meses a comprar: <strong class="meses-a-comprar">
                                Pico
                                    (
                                        A:{{ roundMonths(props.pedir_pico_a) }} - 
                                        B:{{ roundMonths(props.pedir_pico_b) }} - 
                                        C:{{ roundMonths(props.pedir_pico_c) }}
                                    ) ///
                                MdP
                                    (
                                        A:{{ roundMonths(props.pedir_mdp_a) }} - 
                                        B:{{ roundMonths(props.pedir_mdp_b) }} - 
                                        C:{{ roundMonths(props.pedir_mdp_c) }}
                                    ) /// 
                                BA
                                    (
                                        A:{{ roundMonths(props.pedir_ba_a) }} - 
                                        B:{{ roundMonths(props.pedir_ba_b) }} - 
                                        C:{{ roundMonths(props.pedir_ba_c) }}
                                    )
                            </strong><br />
                            Marca/s: <strong class="marcas-a-analizar"> {{props.marcas_a_analizar}} </strong>
                        </td>
                        <td>Recom</td>
                    </tr>
                    <tr>
                        <td colspan="3">Detalles: 
                            <strong class="coeficientes-a-analizar"> {{props.coeficientes_a_analizar}} </strong>
                        </td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td width="18%">MdP a Pico</td>
                        <td width="10%" class="mdp-pico-u num">{{ numberFormat(props.mdp_pico) }}</td>
                        <td width="10%" class="mdp-pico-m num">$ {{ numberFormat(props.mdp_pico_m) }}</td>
                        <td width="10%" class="recom num">{{ numberFormat(props.recom_mdp_pico) }}</td>
                    </tr>

                    <tr>
                        <td width="18%">Promedio</td>
                        <td width="12%" class="num promedio-total-u">{{ numberFormat(props.promedio_total_u) }}</td>
                        <td width="19%" class="num">$ {{ numberFormat(props.promedio_total_m) }}</td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td>BA a Pico</td>
                        <td class="ba-pico-u num">{{ numberFormat(props.ba_pico) }}</td>
                        <td class="ba-pico-m num">$ {{ numberFormat(props.ba_pico_m) }}</td>
                        <td class="recom num">{{ numberFormat(props.recom_ba_pico) }}</td>
                    </tr>

                    <tr>
                        <td>Stock</td>
                        <td class="num stock-total-u">{{ numberFormat(props.stock_total_u) }} ({{ roundMonths(divide(props.stock_total_u, props.promedio_total_u)) }})</td>
                        <td class="num">$ {{ numberFormat(props.stock_total_m) }} ({{ roundMonths(divide(props.stock_total_m, props.promedio_total_m)) }})</td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td>Pico a MdP</td>
                        <td class="pico-mdp-u num">{{ numberFormat(props.pico_mdp) }}</td>
                        <td class="pico-mdp-m num">$ {{ numberFormat(props.pico_mdp_m) }}</td>
                        <td class="recom num">{{ numberFormat(props.recom_pico_mdp) }}</td>
                    </tr>

                    <tr>
                        <td>Pendientes</td>
                        <td class="num pendientes-total-u">{{ numberFormat(props.pendientes_total_u) }} ({{ roundMonths(divide(props.pendientes_total_u, props.promedio_total_u)) }})</td>
                        <td class="num">$ {{ numberFormat(props.pendientes_total_m) }} ({{ roundMonths(divide(props.pendientes_total_m, props.promedio_total_m)) }})</td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td>BA a MdP</td>
                        <td class="ba-mdp-u num">{{ numberFormat(props.ba_mdp) }}</td>
                        <td class="ba-mdp-m num">$ {{ numberFormat(props.ba_mdp_m) }}</td>
                        <td class="recom num">{{ numberFormat(props.recom_ba_mdp) }}</td>
                    </tr>

                    <tr>
                        <td>Recom</td>
                        <td class="num recomendado-total-u">{{ numberFormat(props.recomendado_total_u) }} ({{ roundMonths(divide(props.recomendado_total_u, props.promedio_total_u)) }})</td>
                        <td class="num">$ {{ numberFormat(props.recomendado_total_m) }} ({{ roundMonths(divide(props.recomendado_total_m, props.promedio_total_m)) }})</td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td>Pico a BA</td>
                        <td class="pico-ba-u num">{{ numberFormat(props.pico_ba) }}</td>
                        <td class="pico-ba-m num">$ {{ numberFormat(props.pico_ba_m) }}</td>
                        <td class="recom num">{{ numberFormat(props.recom_pico_ba) }}</td>
                    </tr>

                    <tr>
                        <td>Pedido</td>
                        <td class="p-total-u num">{{ numberFormat(props.p_total_u) }}</td>
                        <td class="p-total-m num">$ {{ numberFormat(props.p_total_m) }}</td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td>MdP a BA</td>
                        <td class="mdp-ba-u num">{{ numberFormat(props.mdp_ba) }}</td>
                        <td class="mdp-ba-m num">$ {{ numberFormat(props.mdp_ba_m) }}</td>
                        <td class="recom num">{{ numberFormat(props.recom_mdp_ba) }}</td>
                    </tr>

                    <tr>
                        <td>Promedio EQ</td>
                        <td class="num promedio-total-eq-u"> {{ numberFormat(props.promedio_total_eq_u) }} </td>
                        <td class="num">$ {{ numberFormat(props.promedio_total_eq_m) }}</td>
                        <td class="no-border padding-0">&nbsp;</td>
                        <td>Pendientes EQ</td>
                        <td class="num total-pendiente-eq-u">{{ numberFormat(props.total_pendiente_eq_u) }}</td>
                        <td class="num">$ {{ numberFormat(props.total_pendiente_eq_m) }}</td>
                        <td class="recom num"></td>
                    </tr>
                </table>
            </th>

            <!-- Pico -->
            <th class="num" v-show="props.showTotals" v-if="props.mostrar_pico == 1">
                <span>{{ numberFormat(props.promedio_pico) }}</span><br />
                <span class="secondary">${{ numberFormat(props.promedio_pico_m) }}</span>
            </th>

            <th class="num" v-show="props.showTotals" v-if="props.mostrar_pico == 1">
                <span>{{ numberFormat(props.stock_pico) }}</span>
                <sup class="secondary"> ({{roundMonths( divide(props.stock_pico, props.promedio_pico) )}}) </sup><br />

                <span class="secondary">${{ numberFormat(props.stock_pico_m) }}</span><br />
                <span class="secondary">({{roundMonths( divide(props.stock_pico_m, props.promedio_pico_m) )}})</span>
            </th>

            <th class="num" v-show="props.showTotals" v-if="props.mostrar_pico == 1">
                <span>{{ numberFormat(props.pendientes_pico) }}</span>
                <sup class="secondary"> ({{roundMonths( divide(props.pendientes_pico, props.promedio_pico) )}}) </sup><br />

                <span class="secondary">${{ numberFormat(props.pendientes_pico_m) }}</span><br />
                <span class="secondary">({{roundMonths( divide(props.pendientes_pico_m, props.promedio_pico_m) )}})</span>
            </th>

            <!-- MdP -->
            <th class="num" v-show="props.showTotals" v-if="props.mostrar_mdp == 1">
                <span>{{ numberFormat(props.promedio_mdp) }}</span><br />
                <span class="secondary">${{ numberFormat(props.promedio_mdp_m) }}</span>
            </th>

            <th class="num" v-show="props.showTotals" v-if="props.mostrar_mdp == 1">
                <span>{{ numberFormat(props.stock_mdp) }}</span>
                <sup class="secondary"> ({{roundMonths( divide(props.stock_mdp, props.promedio_mdp) )}}) </sup><br />

                <span class="secondary">${{ numberFormat(props.stock_mdp_m) }}</span><br />
                <span class="secondary">({{roundMonths( divide(props.stock_mdp_m, props.promedio_mdp_m) )}})</span>
            </th>

            <th class="num" v-show="props.showTotals" v-if="props.mostrar_mdp == 1">
                <span>{{ numberFormat(props.pendientes_mdp) }}</span>
                <sup class="secondary"> ({{roundMonths( divide(props.pendientes_mdp, props.promedio_mdp) )}}) </sup><br />

                <span class="secondary">${{ numberFormat(props.pendientes_mdp_m) }}</span><br />
                <span class="secondary">({{roundMonths( divide(props.pendientes_mdp_m, props.promedio_mdp_m) )}})</span>
            </th>

            <!-- BA -->
            <th class="num" v-show="props.showTotals" v-if="props.mostrar_ba == 1">
                <span>{{ numberFormat(props.promedio_ba) }}</span><br />
                <span class="secondary">${{ numberFormat(props.promedio_ba_m) }}</span>
            </th>

            <th class="num" v-show="props.showTotals" v-if="props.mostrar_ba == 1">
                <span>{{ numberFormat(props.stock_ba) }}</span>
                <sup class="secondary"> ({{roundMonths( divide(props.stock_ba, props.promedio_ba) )}}) </sup><br />

                <span class="secondary">${{ numberFormat(props.stock_ba_m) }}</span><br />
                <span class="secondary">({{roundMonths( divide(props.stock_ba_m, props.promedio_ba_m) )}})</span>
            </th>

            <th class="num" v-show="props.showTotals" v-if="props.mostrar_ba == 1">
                <span>{{ numberFormat(props.pendientes_ba) }}</span>
                <sup class="secondary"> ({{roundMonths( divide(props.pendientes_ba, props.promedio_ba) )}}) </sup><br />

                <span class="secondary">${{ numberFormat(props.pendientes_ba_m) }}</span><br />
                <span class="secondary">({{roundMonths( divide(props.pendientes_ba_m, props.promedio_ba_m) )}})</span>
            </th>
        </tr>

        <tr class="fila-total" v-show="props.showTotals">
            <!-- Pico -->
			<th class="text-left" v-if="props.mostrar_pico == 1">Recom</th>
            <th colspan="2" class="num" v-if="props.mostrar_pico == 1">
                <span class="total-pedir-pico-u">{{ numberFormat(props.recomendado_pico) }}</span> 
                <span class="secondary"> ({{roundMonths( divide(props.recomendado_pico, props.promedio_pico) )}}) </span>
                <div><span class="p">${{ numberFormat(props.recomendado_pico_m) }}</span>
                <span class="secondary"> ({{roundMonths( divide(props.recomendado_pico_m, props.promedio_pico_m) )}}) </span></div>
			</th>
            <!-- MdP -->
			<th class="text-left" v-if="props.mostrar_mdp == 1">Recom</th>
            <th colspan="2" class="num" v-if="props.mostrar_mdp == 1">
                <span class="total-pedir-mdp-u">{{ numberFormat(props.recomendado_mdp) }}</span> 
                <span class="secondary"> ({{roundMonths( divide(props.recomendado_mdp, props.promedio_mdp) )}}) </span>
                <div><span class="p">${{ numberFormat(props.recomendado_mdp_m) }}</span>
                <span class="secondary"> ({{roundMonths( divide(props.recomendado_mdp_m, props.promedio_mdp_m) )}}) </span></div>
			</th>
            <!-- BA -->
			<th class="text-left" v-if="props.mostrar_ba == 1">Recom</th>
            <th colspan="2" class="num" v-if="props.mostrar_ba == 1">
                <span class="total-pedir-ba-u">{{ numberFormat(props.recomendado_ba) }}</span> 
                <span class="secondary"> ({{roundMonths( divide(props.recomendado_ba, props.promedio_ba) )}}) </span>
                <div><span class="p">${{ numberFormat(props.recomendado_ba_m) }}</span>
                <span class="secondary"> ({{roundMonths( divide(props.recomendado_ba_m, props.promedio_ba_m) )}}) </span></div>
			</th>
        </tr>

        <tr class="fila-total">
            <!-- Pico -->
            <th class="text-left" v-if="props.mostrar_pico == 1">Pedido</th>
            <th colspan="2" class="num" v-if="props.mostrar_pico == 1">
                <span class="total-pedir-pico-u">{{props.pedido_pico}}</span> 
                <div><span class="p">${{numberFormat(props.pedido_pico_m)}}</span></div>
			</th>
            <!-- MdP -->
			<th class="text-left" v-if="props.mostrar_mdp == 1">Pedido</th>
            <th colspan="2" class="num" v-if="props.mostrar_mdp == 1">
                <span class="total-pedir-mdp-u">{{props.pedido_mdp}}</span> 
                <div><span class="p">${{numberFormat(props.pedido_mdp_m)}}</span></div>
			</th>
            <!-- BA -->
			<th class="text-left" v-if="props.mostrar_ba == 1">Pedido</th>
            <th colspan="2" class="num" v-if="props.mostrar_ba == 1">
                <span class="total-pedir-ba-u">{{props.pedido_ba}}</span> 
                <div><span class="p">${{numberFormat(props.pedido_ba_m)}}</span></div>
            </th>
        </tr>
    </tbody>
</template>

<script>
    import { ref } from 'vue';

    export default {
   
        props: [
            "usuario",
            "desde",
            "hasta",
            "pedir_pico_a",
            "pedir_pico_b",
            "pedir_pico_c",
            "pedir_mdp_a",
            "pedir_mdp_b",
            "pedir_mdp_c",
            "pedir_ba_a",
            "pedir_ba_b",
            "pedir_ba_c",
            "pedido_rapido",
            "comprar_transferir",
            "mostrar_criticos",
            "mostrar_nuevos",
            "mostrar_alta",
            "mostrar_media",
            "mostrar_baja",
            "mostrar_pico",
            "mostrar_mdp",
            "mostrar_ba",
            "orden",
            "marcas",
            "meses_a_analizar",
            "marcas_a_analizar",
            "coeficientes_a_analizar",
            "promedio_total_u",
            "promedio_total_m",
            "stock_total_u",
            "stock_total_m",
            "pendientes_total_u",
            "pendientes_total_m",
            "recomendado_total_u",
            "recomendado_total_m",
            "p_total_u",
            "p_total_m",
            "promedio_total_eq_u",
            "promedio_total_eq_m",
            "total_pendiente_eq_u",
            "total_pendiente_eq_m",
            "recom_mdp_pico",
            "recom_ba_pico",
            "recom_pico_mdp",
            "recom_ba_mdp",
            "recom_pico_ba",
            "recom_mdp_ba",
            "mdp_pico",
            "ba_pico",
            "pico_mdp",
            "ba_mdp",
            "pico_ba",
            "mdp_ba",
            "mdp_pico_m",
            "ba_pico_m",
            "pico_mdp_m",
            "ba_mdp_m",
            "pico_ba_m",
            "mdp_ba_m",
            "promedio_pico",
            "promedio_mdp",
            "promedio_ba",
            "promedio_pico_m",
            "promedio_mdp_m",
            "promedio_ba_m",
            "stock_pico",
            "stock_mdp",
            "stock_ba",
            "stock_pico_m",
            "stock_mdp_m",
            "stock_ba_m",
            "pendientes_pico",
            "pendientes_mdp",
            "pendientes_ba",
            "pendientes_pico_m",
            "pendientes_mdp_m",
            "pendientes_ba_m",
            "recomendado_pico",
            "recomendado_mdp",
            "recomendado_ba",
            "recomendado_pico_m",
            "recomendado_mdp_m",
            "recomendado_ba_m",
            "pedido_pico",
            "pedido_pico_m",
            "pedido_mdp",
            "pedido_mdp_m",
            "pedido_ba",
            "pedido_ba_m",
            "fecha",
            "fecha_mod",
            "exportado",
            "showTotals",
        ],
        
        setup(props){
            // console.log(props.desde);

            const rowTotals = ref(null);

            const roundMonths = (number) => {
                number = Math.round(10 * number) / 10;
                return number;
            }

            const numberFormat = (number) => {
                number = Math.round(number);
                return number.toLocaleString();
            }

            const divide = (a, b) => {
                a = Number(a);
                b = Number(b);
                if( isNaN(a) || isNaN(b) || b==0 ){
                    return 0;
                }
                return a/b;
            }
            return {
                props,
                roundMonths,
                numberFormat,
                divide,
                rowTotals,
            }
        }
    }
</script>