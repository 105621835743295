import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import NewOrder from './views/NewOrder.vue'
import Spreadsheet from './views/Spreadsheet.vue'
import Calendario from './views/Calendario.vue'

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/new-order',
            name: 'new-order',
            component: NewOrder
        },
        {
            path: '/spreadsheet',
            name: 'spreadsheet',
            component: Spreadsheet
        },
        {
            path: '/spreadsheet/:id',
            name: 'saved-spreadsheet',
            component: Spreadsheet,
            props:true
        },
        {
            path: '/calendario',
            name: 'calendario',
            component: Calendario
        }
    ],
})

export default router
