<template>
  <div>
    <router-view />
    <Wait :wait="appState.wait" />
  </div>
</template>


<script>
  import Wait from "@/components/Wait";
  import { appState } from "@/components/appState.js";

  export default {
    components: { Wait },

    setup(){

      return {
        appState,
      }
    }
  }
</script>
