<template>
    <tr class="desplegable">
        <td>
            <p><input type="checkbox" name="ids[]" :value="order.id" v-model="deleteOrder" @change="updateDeleteOrder" /> Borrar</p>
            <p class="text-center">
                <button @click="toggle()" class="btn btn-xs btn-primary ver-mas">VER +</button>
            </p>
        </td>
        <td>
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                {{order.fecha}}
            </router-link>
        </td>
        <td>
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                <strong>{{order.analisis}}</strong>
            </router-link>
            <br />
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                <strong>Marcas: {{ order.marcas_a_analizar.length > 60 ? order.marcas_a_analizar.substring(0,60) : order.marcas_a_analizar }} ...</strong>
            </router-link>
            <br />

            <div class="info-complementaria" v-show="moreState">
                <strong>Meses a comprar: {{order.stock_a_pedir}} </strong><br />
                Fecha mod: {{order.fecha_mod}} <br />
                <!-- (A, B, C, críticos, nuevos   -///-  pedido rápido   -///-   autocompletado  -///-  solo transferir ) -->
                Detalles: {{order.coeficientes_a_analizar}} <br />
                Usuario: {{order.usuario}}
            </div>
        </td>
        <td>
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                Pedido: {{order.p_total_u}}<br />
                Pedido: ${{order.p_total_m}}<br />
                Pendientes: {{order.pendientes_total_u}}
            </router-link>
            <div class="info-complementaria" v-show="moreState">
                <span class='text-nowrap'>Promedio mensual: <span class='black'>{{order.promedio_total_u}}</span></span><br />
                Promedio mensual eq: <span class='black'>{{order.promedio_total_eq_u}}</span><br />
                <span class='text-nowrap'>
                    Stock 
                    <i class='glyphicon glyphicon-info-sign' style='color:#333;' title='Stock total de todos los artículos en las tres sucursales'></i>:
                    <span class='black'>{{order.stock_total_u}}</span>
                </span><br />
                <span class='text-nowrap'>Recomendado: <span class='black'>{{order.recomendado_total_u}}</span></span>
            </div>
        </td>
        <td>
            <div class="info-complementaria" v-show="moreState">
                <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                    Mdp a Pico: {{order.mdp_pico}}<br />
                    BA a Pico: {{order.ba_pico}}<br />
                    Pico a MdP: {{order.pico_mdp}}<br />
                    BA a MdP: {{order.ba_mdp}}<br />
                    Pico a BA: {{order.pico_ba}}<br />
                    MdP a BA: {{order.mdp_ba}}
                </router-link>
            </div>
        </td>
        <td>
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                <span class="text-nowrap">Pedidos: <span class="black">{{order.pedido_pico}}</span></span><br />
                <span class="text-nowrap">Pedido: <span class="black">{{order.pedido_pico_m}}</span></span><br />
                <span class="text-nowrap">Pendientes: <span class="black">{{order.pendientes_pico}}</span></span><br />
            </router-link>
            <div class="info-complementaria" v-show="moreState">
                <span class="text-nowrap">Promedio: <span class="black">{{order.promedio_pico}}</span></span><br />
                <span class="text-nowrap">Stock <i class="glyphicon glyphicon-info-sign" style="color:#333;" title="Stock total de todos los artículos en esta sucursal"></i>:
                <span class="black">{{order.stock_pico}}</span></span><br />
                <span class="text-nowrap">Recomendados: <span class="black">{{order.recomendado_pico}}</span></span><br />
            </div>
        </td>
        <td>
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                <span class="text-nowrap">Pedidos: <span class="black">{{order.pedido_mdp}}</span></span><br />
                <span class="text-nowrap">Pedido: <span class="black">{{order.pedido_mdp_m}}</span></span><br />
                <span class="text-nowrap">Pendientes: <span class="black">{{order.pendientes_mdp}}</span></span><br />
            </router-link>
            <div class="info-complementaria" v-show="moreState">
                <span class="text-nowrap">Promedio: <span class="black">{{order.promedio_mdp}}</span></span><br />
                <span class="text-nowrap">Stock <i class="glyphicon glyphicon-info-sign" style="color:#333;" title="Stock total de todos los artículos en esta sucursal"></i>:
                <span class="black">{{order.stock_mdp}}</span></span><br />
                <span class="text-nowrap">Recomendados: <span class="black">{{order.recomendado_mdp}}</span></span><br />
            </div>
        </td>
        <td>
            <router-link :to="{ name: 'saved-spreadsheet', params: { id: order.id }}">
                <span class="text-nowrap">Pedidos: <span class="black">{{order.pedido_ba}}</span></span><br />
                <span class="text-nowrap">Pedido: <span class="black">{{order.pedido_ba_m}}</span></span><br />
                <span class="text-nowrap">Pendientes: <span class="black">{{order.pendientes_ba}}</span></span><br />
            </router-link>
            <div class="info-complementaria" v-show="moreState">
                <span class="text-nowrap">Promedio: <span class="black">{{order.promedio_ba}}</span></span><br />
                <span class="text-nowrap">Stock <i class="glyphicon glyphicon-info-sign" style="color:#333;" title="Stock total de todos los artículos en esta sucursal"></i>:
                <span class="black">{{order.stock_ba}}</span></span><br />
                <span class="text-nowrap">Recomendados: <span class="black">{{order.recomendado_ba}}</span></span><br />
            </div>
        </td>
        <td>
            {{order.exportado == 1 ? 'Exportado' : '' }}
            {{order.sobrepedido == 1 ? '<p><button class="btn-sobrepedido btn btn-danger btn-sm">Sobrepedido></button></p>' : '' }}
        </td>
    </tr>
</template>

<script>
    import { ref } from 'vue';

    export default {
   
        props: ["order"],
        
        setup(props, context){
            const moreState = ref(0)
            const deleteOrder = ref(false)

            const toggle = () => {
                moreState.value = moreState.value == 0 ? 1 : 0;
            }

            const updateDeleteOrder = () => {
                // console.log(deleteOrder.value)
                let data = {
                    id: props.order.id,
                    delete: deleteOrder.value
                }
                context.emit("updateDeleteOrder", data)
            }

            return {
                moreState,
                deleteOrder,
                props,
                toggle,
                updateDeleteOrder
            }
        }
    }
</script>
