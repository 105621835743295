<template>
    <main-header />
    <div class="alert alert-info">
        <h3>Análisis Realizados</h3>

        <!-- <a href="./new-order" class="btn btn-primary">Nuevo Análisis</a> -->
        <router-link to="new-order" class="btn btn-primary">Nuevo Análisis</router-link>
        | <input type="submit" class="btn btn-primary" name="borrar" value="Borrar" @click="borrar" />
        | <input type="button" class="btn btn-primary" name="actualizar-base" id="actualizar-base" value="Actualizar Base" />
        | <input type="button" name="analisis-rapido-criticos" id="analisis-rapido-criticos" class="btn btn-danger" value="Análisis rápido de críticos" />
        | <router-link to="calendario" class="btn btn-success">Calendario</router-link>
        | <router-link to="#" class="btn btn-primary">Cruce de pendientes 2</router-link>
        <br /><br />
        <div class="inline-block" id="ultima-actualizacion"></div>
        <br />
        <table class="table table-bordered table-responsive listado-pc" id="tabla-totales" style="background:#f5f5f5;">
            <tr>
                <th></th>
                <th>Fecha</th>
                <th>Análisis</th>
                <th>Totales</th>
                <th>Transferir</th>
                <th>Pico</th>
                <th>MdP</th>
                <th>BA</th>
                <th>Exportado</th>
            </tr>
            <order-line 
                v-for="order in orders" 
                :order="order" 
                :key="order.id"
                @updateDeleteOrder="updateDeleteOrder"
            ></order-line>
        </table>
    </div>
</template>

<script> 
    import { ref } from "vue";
    import axios from 'axios';
    import OrderLine from "@/components/OrderLine.vue";
	import MainHeader from '@/components/MainHeader.vue';

    export default {
        components: {
            OrderLine,
            MainHeader,
        },

        setup(){
            const orders = ref([]);
            const deleteList = ref([]);

            // console.log(process.env.VUE_APP_API)
            fetch( process.env.VUE_APP_API + "orders")
                .then( res => res.json() )
                .then( data => {
                    orders.value = data
                })

            const updateDeleteOrder = (e) => {
                // console.log(e)
                if( e.delete ){
                    deleteList.value.push(e.id)
                }else{
                    const index = deleteList.value.indexOf(e.id);
                    if (index > -1) {
                        deleteList.value.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }
                // console.log(deleteList.value)
            }

            const borrar = () => {
                // console.log('deletelist', deleteList.value)
                if( confirm("¿Está seguro de borrar los análisis seleccionados?") ){
                    // console.log(process.env.VUE_APP_API + "orders/delete/" + key)

                    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                    axios.post( `${process.env.VUE_APP_API}orders/delete`, {'idsToDelete': deleteList.value})
                        .then( response => {
                            orders.value = response.data
                            deleteList.value = []
                            // console.log(orders.value)
                        })
                        .catch( (error) => {
                            deleteList.value = []
                            console.error("Error guardando los datos", error.message);
                        })

                }
            }
            return {
                orders,
                OrderLine,
                borrar,
                updateDeleteOrder
            }
        }
    }
</script>

<style scoped>
    .alert-info{
        margin:22px;
    }
</style>