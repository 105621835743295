import { reactive } from 'vue'

export const appState = reactive({
  wait: false,

  tabPosition: [1,1],

  startWaiting() {
    this.wait = true
  },
  stopWaiting() {
    this.wait = false
  }
})

