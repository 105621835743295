<template>
    <div>
        <p class="strong open-button" @click="getPending(props.idArticulo)">{{props.codigoParticular}}</p>
        <div class="fancybox-contenedor" v-if="show">
            <table class='table table-bordered table-responsive' style='margin:0'>
                <tbody>
                    <tr class='alert-info'>
                        <th></th>
                        <th>Vencidos</th>
                        <th>Mes actual</th>
                        <th>Mes siguiente</th>
                        <th>Luego</th>
                    </tr>
                    <tr>
                        <td>Pico</td>
                        <td class="text-right">{{data.pasado.pico}}</td>
                        <td class="text-right">{{data.actual.pico}}</td>
                        <td class="text-right">{{data.siguiente.pico}}</td>
                        <td class="text-right">{{data.luego.pico}}</td>
                    </tr>
                    <tr>
                        <td>MdP</td>
                        <td class="text-right">{{data.pasado.mdp}}</td>
                        <td class="text-right">{{data.actual.mdp}}</td>
                        <td class="text-right">{{data.siguiente.mdp}}</td>
                        <td class="text-right">{{data.luego.mdp}}</td>
                    </tr>
                    <tr>
                        <td>Ba</td>
                        <td class="text-right">{{data.pasado.ba}}</td>
                        <td class="text-right">{{data.actual.ba}}</td>
                        <td class="text-right">{{data.siguiente.ba}}</td>
                        <td class="text-right">{{data.luego.ba}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
	import { ref } from 'vue';
	import axios from 'axios';

    export default {

        props: ["idArticulo", "codigoParticular"],
        
        setup(props){
            const show = ref(false);
            var data = ref({})

            const getPending = (idProduct) => {
                axios.get(`${process.env.VUE_APP_API}pending/${props.idArticulo}`)
                .then(response => {
                    data.value = response.data

                    console.log(idProduct, response)
                    // data.value = response.data
                    // keys.value = Object.keys(data.value)

                    show.value = true
                }).catch(console.error)
            }

            window.addEventListener('click', ()=>{
                show.value = false
                data.value = {}
                // keys.value = []
            });

            return {
                props,
                show,
                data,
                getPending
            }
        }
    }
</script>
