<template>
    <div v-show="props.wait" class="wait-overlay"></div>
</template>

<script>
    export default {
        props: ["wait"],

        setup(props){
            return {
                props,
            }
        }
    }
</script>

<style scoped>
    .wait-overlay {
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-image: url('~@/assets/loading.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-color:rgba(0,0,0,0.7);
        z-index:9999;
        display:block;
    }
</style>