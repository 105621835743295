<template>
	<MainHeader />
    <form method="post" class="form-horizontal">
		<div class="alert alert-info row">
			<div class="col-md-8">
				<div class="row">			
					<div class="col-md-2">
						<router-link :to="{ name: 'home' }" class="btn btn-primary">Volver</router-link>
					</div>
					<div class="col-md-8">
						<h3>Nuevo análisis</h3>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<label class="control-label" for="analisis">Nombre Análisis:</label>
						<input 
							type="text" 
							class="form-control" 
							placeholder="Análisis" 
							autofocus="autofocus"
							v-model="analisis"
						/>
						<br />
						<br />
						<p><strong><u>Fechas para calcular el promedio</u></strong></p>
						<div class="row">
							<div class="col-md-6">
								<label class="control-label" for="desde">Desde:</label>
								<input type="date" id="desde" class="form-control" placeholder="Desde DD/MM/AAAA" v-model="desde" />
							</div>
							<div class="col-md-6">
								<label class="control-label" for="hasta">Hasta:</label>
								<input type="date" id="hasta" class="form-control" placeholder="Hasta DD/MM/AAAA" v-model="hasta" />
								<div style="color:#d00;padding-top:8px;text-align:right;">({{ calcularDiasDiff() }} Meses)</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div>
							<h3>Stock a pedir (En meses):</h3>
							<table class="meses-a-pedir-table">
								<tr>
									<th></th>
									<th>Pico</th>
									<th>MdQ</th>
									<th>BA</th>
								</tr>
								<tr>
									<td>A</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.A.pico"
											@change="updateAll"
										/>
									</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.A.mdp"
											@change="updateAll"
										/>
									</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.A.ba"
											@change="updateAll"
										/>
									</td>
								</tr>
								<tr>
									<td>B</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.B.pico"
										/>
									</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.B.mdp"
										/>
									</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.B.ba"
										/>
									</td>
								</tr>
								<tr>
									<td>C</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.C.pico"
										/>
									</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.C.mdp"
										/>
									</td>
									<td>
										<input 
											type="text" 
											class="form-control" 
											v-model="stockPedir.C.ba"
										/>
									</td>
								</tr>
							</table>
						</div>
						<div>
							<br />
							<label class="control-label" for="promedio-irregular">Promedio Irregular:</label>
							<input 
								type="text" 
								class="form-control" 
								id="promedio-irregular" 
								placeholder="Promedio Irregular" 
								title="Escriba cuantas veces considera que tiene que variar el promedio para ser considerado irregular"
								v-model="promedioIrregular"
							/>
						</div>
						<div class="clearfix"></div>
						<div style="color:#d00;padding:6px 16px;">Para decimales usar el punto y no la coma</div>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col-md-6">
						<p><strong><u>Pedido Rápido</u></strong></p>
						<input type="radio" id="pedido-rapido-si" v-model="pedidoRapido" value="1" />
						<label class="control-label" for="pedido-rapido-si">Si</label>
						<br />
						<input type="radio" id="pedido-rapido-no" v-model="pedidoRapido" value="0" />
						<label class="control-label" for="pedido-rapido-no">No</label><br />
						(El pedido rápido solo muestra los artículos que recomienda pedir)
					</div>
					<div class="col-md-6">
						<p><strong><u>Comprar / Transferir</u></strong></p>
						<input type="radio" id="comprar-transferir" v-model="comprarTransferir" value="1" />
						<label class="control-label" for="comprar-transferir">Comprar + Transferir</label>
						<br />
						<input type="radio" id="solo-transferir" v-model="comprarTransferir" value="0" />
						<label class="control-label" for="solo-transferir">Solo transferir</label>
						<br />
						<br />
						<div class="row">
							<div class="col-md-12">
								<p><strong><u>Oferta</u></strong></p>
								<div class="col-md-4">
									<input type="radio" id="oferta-especial-no" v-model="ofertaEspecial" value="0" />
									<label class="control-label" for="oferta-especial-no">NO</label>
									<input type="radio" id="oferta-especial-si" v-model="ofertaEspecial" value="1" />
									<label class="control-label" for="oferta-especial-si">SI</label>
								</div>
								<div class="col-md-6">
									<label class="control-label" for="oferta-especial-porcentaje">% De Descuento</label>
									<input 
										type="text" 
										class="form-control" 
										name="oferta-especial-porcentaje" 
										id="oferta-especial-porcentaje" 
										value="" 
										placeholder="% Desc" 
										pattern="^[0-9]{0,3}(\.|,)?[0-9]{0,3}$" 
										title="Escriba el porcentaje de descuento" 
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div class="form-group">
					<div class="col-md-3">
						<p><strong><u>Artículos a Mostrar</u></strong></p>
						<div class="col-xs-12">
							<input type="checkbox" name="mostrar-criticos" id="mostrar-criticos" v-model="mostrarCriticos" />
							<label class="control-label" for="mostrar-criticos">Críticos</label>
						</div>
						<div class="col-xs-12">
							<input type="checkbox" name="mostrar-nuevos" id="mostrar-nuevos" v-model="mostrarNuevos" />
							<label class="control-label" for="mostrar-nuevos">Nuevos</label>
						</div>
					</div>
					<div class="col-xs-9">
						<div class="col-xs-12">
							<input type="checkbox" name="mostrar-alta-rotacion" id="mostrar-alta-rotacion" v-model="mostrarAlta" />
							<label class="control-label" for="mostrar-alta-rotacion">Productos A (alta rotación, hasta 70% de ventas en unidades)</label>
						</div>
						<div class="col-xs-12">
							<input type="checkbox" name="mostrar-media-rotacion" id="mostrar-media-rotacion" v-model="mostrarMedia" />
							<label class="control-label" for="mostrar-media-rotacion">Productos B (media rotación, 25% de las ventas en unidades)</label>
						</div>
						<div class="col-xs-12">
							<input type="checkbox" name="mostrar-baja-rotacion" id="mostrar-baja-rotacion" v-model="mostrarBaja" />
							<label class="control-label" for="mostrar-baja-rotacion">Productos C (baja rotación, 5% de las ventas en unidades)</label>
						</div>
					</div>
					<div class="clearfix"></div>
				</div>
				<hr />
				<div class="col-md-6">
					<div class="form-group">
						<p><strong><u>Orden</u></strong></p>
						<div class="clearfix"></div>
						<div class="col-md-12">
							<div class="col-xs-12">
								<input type="radio" id="orden-promedio" value="0" v-model="orden" />
								<label class="control-label" for="orden-promedio">Por Rotación (Críticos arriba)</label>
								<br />
								<input type="radio" id="orden-codigo" value="1" v-model="orden" />
								<label class="control-label" for="orden-codigo">Por código (Críticos arriba)</label>
								<br />
								<input type="radio" id="orden-codigo-solo" value="2" v-model="orden" />
								<label class="control-label" for="orden-codigo-solo">Por código solamente</label>
								<br />
								<input type="radio" id="orden-promedio" value="3" v-model="orden" />
								<label class="control-label" for="orden-promedio">Por promedio</label>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<p><strong><u>Sucursales a Mostrar</u></strong></p>
						<div class="clearfix"></div>
						<div class="col-md-12">
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-pico" id="mostrar-pico" v-model="mostrarPico" />
								<label class="control-label" for="mostrar-pico">Mostrar Pico</label>
							</div>
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-mdp" id="mostrar-mdp" v-model="mostrarMdp" />
								<label class="control-label" for="mostrar-mdp">Mostrar MdP</label>
							</div>
							<div class="col-xs-12">
								<input type="checkbox" name="mostrar-ba" id="mostrar-ba" v-model="mostrarBa" />
								<label class="control-label" for="mostrar-ba">Mostrar BA</label>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label class="col-md-12" for="brands">brands:</label>
					<div class="clearfix"></div>
					<div class="col-md-12">
						<div class="box-1">
							<div class="box-2">
								<!-- <brand-box 
									v-for="brand in brands" 
									:brand="brand" 
									:key="brand.id"
									v-model="selectedBrands"
								></brand-box> -->
								<div v-for="brand in brands" :key="brand.id_marca">
									<input 
										type="checkbox" 
										class="ch-brands" 
										name="brands[]" 
										:id="'id-' + brand.id_marca"
										:value="brand.id_marca" 
										v-model="selectedBrands"
									/>
									<label :for="'id-' + brand.id_marca">{{brand.nombre_marca}}</label>
									<div class="clearfix"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>
				<button type="button" @click="save" class="btn btn-primary">Continuar</button>
			</div>
		</div>
	</form>
</template>

<script>
	import { ref, reactive } from 'vue';
	// import router from 'vue-router';
	import { useRouter } from 'vue-router'
	// import BrandBox from '../components/BrandBox.vue';
	import axios from 'axios';
	import MainHeader from '@/components/MainHeader.vue';
	import { appState } from "@/components/appState.js";

	export default {
		// components: { BrandBox },
        components: { MainHeader },

		setup(){
            const brands = ref([]);
			const analisis = ref('');

			const today = new Date();
			const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()).toISOString().split('T')[0];
			const todayDate = today.toISOString().split('T')[0];

			const desde = ref( sixMonthsAgo );
			const hasta = ref( todayDate );

			const stockPedir = reactive({
				A: {
					pico: '',
					mdp: '',
					ba: ''
				},
				B: {
					pico: '',
					mdp: '',
					ba: ''
				},
				C: {
					pico: '',
					mdp: '',
					ba: ''
				}
			});
			const pedidoRapido = ref(0);
			const comprarTransferir = ref(1);
			const orden = ref(2);
			const mostrarCriticos = ref(true);
			const mostrarNuevos = ref(true);
			const mostrarAlta = ref(true);
			const mostrarMedia = ref(true);
			const mostrarBaja = ref(true);
			const mostrarPico = ref(true);
			const mostrarMdp = ref(true);
			const mostrarBa = ref(true);
			const promedioIrregular = ref(5);
			const ofertaEspecial = ref(0);

			const router = useRouter()

            fetch( process.env.VUE_APP_API + "brands")
                .then( res => res.json() )
                .then( data => {
                brands.value = data
            })

			const selectedBrands = ref([]);

			function save(){
				appState.startWaiting();
				// console.log(selectedBrands.value);
				const payload = {
					"analisis": analisis.value,
					"usuario": '',
					"desde": desde.value,
					"hasta": hasta.value,
					"pedir-pico-a": stockPedir.A.pico,
					"pedir-pico-b": stockPedir.B.pico,
					"pedir-pico-c": stockPedir.C.pico,
					"pedir-mdp-a": stockPedir.A.mdp,
					"pedir-mdp-b": stockPedir.B.mdp,
					"pedir-mdp-c": stockPedir.C.mdp,
					"pedir-ba-a": stockPedir.A.ba,
					"pedir-ba-b": stockPedir.B.ba,
					"pedir-ba-c": stockPedir.C.ba,
					"pedido-rapido": pedidoRapido.value ? 1 : 0,
					"comprar-transferir": comprarTransferir.value,
					"orden": orden.value,
					"mostrar-criticos": mostrarCriticos.value ? 1 : 0,
					"mostrar-nuevos": mostrarNuevos.value ? 1 : 0,
					"mostrar-alta": mostrarAlta.value ? 1 : 0,
					"mostrar-media": mostrarMedia.value ? 1 : 0,
					"mostrar-baja": mostrarBaja.value ? 1 : 0,
					"mostrar-pico": mostrarPico.value ? 1 : 0,
					"mostrar-mdp": mostrarMdp.value ? 1 : 0,
					"mostrar-ba": mostrarBa.value ? 1 : 0,
					"promedio-irregular": promedioIrregular.value,
					"oferta-especial": ofertaEspecial.value,
					"marcas": selectedBrands.value,
				}

				// const payload = { 'prueba': 'prueba' };

				axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
				var orderSaved = axios.post( process.env.VUE_APP_API + "orders", payload)
					.then( (response) => {
						router.push({ name: 'saved-spreadsheet', params: { id: response.data.id }});
						appState.stopWaiting();
					})
					.catch(error => {
						console.error("Error guardando los datos", error.message);
						appState.stopWaiting();
					});

				// console.log(orderSaved);
				return orderSaved;
			}

			function updateAll(event){
				Object.keys(stockPedir).forEach((key) => {
					// console.log(key, stockPedir[key]);
					Object.keys(stockPedir[key]).forEach((key2) => {
						if(stockPedir[key][key2]==''){
							stockPedir[key][key2] = event.target.value;
						}
						// console.log(key2, stockPedir[key][key2]);
					});
				});
			}

			function calcularDiasDiff(){
				var fecha1 = new Date(desde.value.substring(0,4),desde.value.substring(5,7),desde.value.substring(8,10));
				var fecha2 = new Date(hasta.value.substring(0,4),hasta.value.substring(5,7),hasta.value.substring(8,10));
				var diasDif = fecha2.getTime() - fecha1.getTime();
				var dias = Math.round(diasDif/(1000 * 60 * 60 * 24));
				if(dias>0){
					var meses = (dias+1)/30.5;
					meses = Math.round(meses * 10) / 10;
					// q('#meses-diff').html('(' + meses + ' Meses)');
					return meses
				}
				return 0
			}

            return {
                brands,
				selectedBrands,
				analisis,
				desde,
				hasta,
				stockPedir,
				pedidoRapido,
				comprarTransferir,
				orden,
				mostrarCriticos,
				mostrarNuevos,
				mostrarAlta,
				mostrarMedia,
				mostrarBaja,
				mostrarPico,
				mostrarMdp,
				mostrarBa,
				promedioIrregular,
				ofertaEspecial,
				save,
				updateAll,
				calcularDiasDiff
            }
        }
    }
</script>