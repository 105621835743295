<template>
    <colgroup>
        <col span="3" class="columna" />
        <col span="3" class="columna col_pico" v-if="props.mostrar_pico == 1" />
        <col span="3" class="columna col_mdp" v-if="props.mostrar_mdp == 1" />
        <col span="3" class="columna col_ba" v-if="props.mostrar_ba == 1" />
    </colgroup>
    <Totals 
        :usuario="props.usuario"
        :desde="props.desde"	
        :hasta="props.hasta"	
        :pedir_pico_a="props.pedir_pico_a"
        :pedir_pico_b="props.pedir_pico_b"	
        :pedir_pico_c="props.pedir_pico_c"
        :pedir_mdp_a="props.pedir_mdp_a"
        :pedir_mdp_b="props.pedir_mdp_b"
        :pedir_mdp_c="props.pedir_mdp_c"
        :pedir_ba_a="props.pedir_ba_a"
        :pedir_ba_b="props.pedir_ba_b"
        :pedir_ba_c="props.pedir_ba_c"
        :pedido_rapido="props.pedido_rapido"
        :comprar_transferir="props.comprar_transferir"
        :mostrar_criticos="props.mostrar_criticos"
        :mostrar_nuevos="props.mostrar_nuevos"
        :mostrar_alta="props.mostrar_alta"
        :mostrar_media="props.mostrar_media"
        :mostrar_baja="props.mostrar_baja"
        :mostrar_pico="props.mostrar_pico"
        :mostrar_mdp="props.mostrar_mdp"
        :mostrar_ba="props.mostrar_ba"
        :orden="props.orden"
        :marcas="props.marcas"    
        :meses_a_analizar="props.meses_a_analizar"
        :marcas_a_analizar="props.marcas_a_analizar"    
        :coeficientes_a_analizar="props.coeficientes_a_analizar"
        :promedio_total_u="props.promedio_total_u"
        :promedio_total_m="props.promedio_total_m"
        :stock_total_u="props.stock_total_u"
        :stock_total_m="props.stock_total_m"
        :pendientes_total_u="props.pendientes_total_u"
        :pendientes_total_m="props.pendientes_total_m"
        :recomendado_total_u="props.recomendado_total_u"
        :recomendado_total_m="props.recomendado_total_m"
        :p_total_u="props.p_total_u"
        :p_total_m="props.p_total_m"
        :promedio_total_eq_u="props.promedio_total_eq_u"
        :promedio_total_eq_m="props.promedio_total_eq_m"
        :total_pendiente_eq_u="props.total_pendiente_eq_u"
        :total_pendiente_eq_m="props.total_pendiente_eq_m"
        :recom_mdp_pico="props.recom_mdp_pico"
        :recom_ba_pico="props.recom_ba_pico"
        :recom_pico_mdp="props.recom_pico_mdp"
        :recom_ba_mdp="props.recom_ba_mdp"
        :recom_pico_ba="props.recom_pico_ba"
        :recom_mdp_ba="props.recom_mdp_ba"
        :mdp_pico="props.mdp_pico"
        :ba_pico="props.ba_pico"
        :pico_mdp="props.pico_mdp"
        :ba_mdp="props.ba_mdp"
        :pico_ba="props.pico_ba"
        :mdp_ba="props.mdp_ba"
        :mdp_pico_m="props.mdp_pico_m"
        :ba_pico_m="props.ba_pico_m"
        :pico_mdp_m="props.pico_mdp_m"
        :ba_mdp_m="props.ba_mdp_m"
        :pico_ba_m="props.pico_ba_m"
        :mdp_ba_m="props.mdp_ba_m"
        :promedio_pico="props.promedio_pico"
        :promedio_mdp="props.promedio_mdp"
        :promedio_ba="props.promedio_ba"
        :promedio_pico_m="props.promedio_pico_m"
        :promedio_mdp_m="props.promedio_mdp_m"
        :promedio_ba_m="props.promedio_ba_m"
        :stock_pico="props.stock_pico"
        :stock_mdp="props.stock_mdp"
        :stock_ba="props.stock_ba"
        :stock_pico_m="props.stock_pico_m"
        :stock_mdp_m="props.stock_mdp_m"
        :stock_ba_m="props.stock_ba_m"
        :pendientes_pico="props.pendientes_pico"
        :pendientes_mdp="props.pendientes_mdp"
        :pendientes_ba="props.pendientes_ba"
        :pendientes_pico_m="props.pendientes_pico_m"
        :pendientes_mdp_m="props.pendientes_mdp_m"
        :pendientes_ba_m="props.pendientes_ba_m"
        :recomendado_pico="props.recomendado_pico"
        :recomendado_mdp="props.recomendado_mdp"
        :recomendado_ba="props.recomendado_ba"
        :recomendado_pico_m="props.recomendado_pico_m"
        :recomendado_mdp_m="props.recomendado_mdp_m"
        :recomendado_ba_m="props.recomendado_ba_m"
        :pedido_pico="props.pedido_pico"
        :pedido_pico_m="props.pedido_pico_m"
        :pedido_mdp="props.pedido_mdp"
        :pedido_mdp_m="props.pedido_mdp_m"
        :pedido_ba="props.pedido_ba"
        :pedido_ba_m="props.pedido_ba_m"
        :fecha="props.fecha"    
        :fecha_mod="props.fecha_mod"    
        :exportado="props.exportado"
        :showTotals="props.showTotals"
    />

    <tbody class="thead-group">
        <tr class="thead">
            <th rowspan="2" class="info thead2">Código</th>
            <th rowspan="2" class="info thead2">Descripción</th>
            <th rowspan="2" class="info thead2">P. Lista</th>
            <th colspan="3" v-if="props.mostrar_pico == 1">Pico</th>
            <th colspan="3" v-if="props.mostrar_mdp == 1">MdP</th>
            <th colspan="3" v-if="props.mostrar_ba == 1">BA</th>
        </tr>
        <tr class="thead thead2">
            <th v-if="props.mostrar_pico == 1">Prom</th>
            <th v-if="props.mostrar_pico == 1">Stock</th>
            <th v-if="props.mostrar_pico == 1">Pend</th>
            <th v-if="props.mostrar_mdp == 1">Prom</th>
            <th v-if="props.mostrar_mdp == 1">Stock</th>
            <th v-if="props.mostrar_mdp == 1">Pend</th>
            <th v-if="props.mostrar_ba == 1">Prom</th>
            <th v-if="props.mostrar_ba == 1">Stock</th>
            <th v-if="props.mostrar_ba == 1">Pend</th>
        </tr>
    </tbody>
</template>

<script>
    import Totals from '@/components/Totals'

    export default {
        components: { Totals },

        props: [
            "usuario",
            "desde",
            "hasta",
            "pedir_pico_a",
            "pedir_pico_b",
            "pedir_pico_c",
            "pedir_mdp_a",
            "pedir_mdp_b",
            "pedir_mdp_c",
            "pedir_ba_a",
            "pedir_ba_b",
            "pedir_ba_c",
            "pedido_rapido",
            "comprar_transferir",
            "mostrar_criticos",
            "mostrar_nuevos",
            "mostrar_alta",
            "mostrar_media",
            "mostrar_baja",
            "mostrar_pico",
            "mostrar_mdp",
            "mostrar_ba",
            "orden",
            "marcas",
            "meses_a_analizar",
            "marcas_a_analizar",
            "coeficientes_a_analizar",
            "promedio_total_u",
            "promedio_total_m",
            "stock_total_u",
            "stock_total_m",
            "pendientes_total_u",
            "pendientes_total_m",
            "recomendado_total_u",
            "recomendado_total_m",
            "p_total_u",
            "p_total_m",
            "promedio_total_eq_u",
            "promedio_total_eq_m",
            "total_pendiente_eq_u",
            "total_pendiente_eq_m",
            "recom_mdp_pico",
            "recom_ba_pico",
            "recom_pico_mdp",
            "recom_ba_mdp",
            "recom_pico_ba",
            "recom_mdp_ba",
            "mdp_pico",
            "ba_pico",
            "pico_mdp",
            "ba_mdp",
            "pico_ba",
            "mdp_ba",
            "mdp_pico_m",
            "ba_pico_m",
            "pico_mdp_m",
            "ba_mdp_m",
            "pico_ba_m",
            "mdp_ba_m",
            "promedio_pico",
            "promedio_mdp",
            "promedio_ba",
            "promedio_pico_m",
            "promedio_mdp_m",
            "promedio_ba_m",
            "stock_pico",
            "stock_mdp",
            "stock_ba",
            "stock_pico_m",
            "stock_mdp_m",
            "stock_ba_m",
            "pendientes_pico",
            "pendientes_mdp",
            "pendientes_ba",
            "pendientes_pico_m",
            "pendientes_mdp_m",
            "pendientes_ba_m",
            "recomendado_pico",
            "recomendado_mdp",
            "recomendado_ba",
            "recomendado_pico_m",
            "recomendado_mdp_m",
            "recomendado_ba_m",
            "pedido_pico",
            "pedido_pico_m",
            "pedido_mdp",
            "pedido_mdp_m",
            "pedido_ba",
            "pedido_ba_m",
            "fecha",
            "fecha_mod",
            "exportado",
            "showTotals",
        ],
                
        setup(props){
            // console.log(props.desde);

            return {
                props,
                Totals
            }
        }
    }
</script>