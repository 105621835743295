<template>
    <div class="alert alert-info">
        <h3>Análisis Realizados</h3>

        <a href="./new-order" class="btn btn-primary">Nuevo Análisis</a>
        | <input type="submit" class="btn btn-primary" name="borrar" value="Borrar" />
        | <input type="button" class="btn btn-primary" name="actualizar-base" id="actualizar-base" value="Actualizar Base" />
        | <input type="button" name="analisis-rapido-criticos" id="analisis-rapido-criticos" class="btn btn-danger" value="Análisis rápido de críticos" />
        | <a href="./calendario" class="btn btn-success">Calendario</a>
        | <a href="/cruce-proveedor-1/" class="btn btn-primary">Cruce de pendientes 2</a>
        <br /><br />
        <div class="inline-block" id="ultima-actualizacion"></div>
        <br />
        <table class="table table-bordered table-responsive listado-pc" style="background:#f5f5f5;">
            <tr>
                <th></th>
                <th>Fecha</th>
                <th>Análisis</th>
                <th>Totales</th>
                <th>Transferir</th>
                <th>Pico</th>
                <th>MdP</th>
                <th>BA</th>
                <th>Exportado</th>
            </tr>
        </table>
    </div>
</template>

<script>

</script>

<style scoped>
    .alert-info{
        margin:22px;
    }
</style>