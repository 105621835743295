<template>
    <div>
        <p>
            <input
            type="button" 
            name="ver-detalles-articulo[]"
            class="ver-detalles-articulo btn btn-primary btn-sm"
            @click="getDetails(props.idArticulo)"
            value="Detalles"
            />
        </p>
        <div class="fancybox-contenedor" v-if="show">
            <table class="table table-bordered table-responsive" style="margin:0;">
                <tr class='alert-info'>
                    <th v-for="(period, index) in keys" :key="index" style="padding:3px 5px;border: solid 1px #555;">
                        {{formatDate(period)}}
                    </th>
                </tr>
                <tr>
                    <td v-for="(period, index) in keys" :key="index" style="padding:3px 5px;border: solid 1px #555;">
                        {{ data[period] }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>


<script>
	import { ref } from 'vue';
	import { useRoute } from 'vue-router'
	import axios from 'axios';

    export default {
   
        props: ["idArticulo"],
        
        setup(props){
            const route = useRoute();  
            const id = route.params.id;
            const show = ref(false);
            var data = ref({})
            var keys = ref([])

            const getDetails = (idProduct) => {
                axios.get(`${process.env.VUE_APP_API}order/${id}/sales-details/${idProduct}`)
                .then(response => {

                    data.value = response.data
                    keys.value = Object.keys(data.value)

                    show.value = true
                }).catch(console.error)
            }

            window.addEventListener('click', ()=>{
                show.value = false
                data.value = {}
                keys.value = []
            });

            function formatDate(date){
                var newDate = date.split("-")
                return newDate[1] + '/' + newDate[0]
            }

            return {
                props,
                show,
                data,
                keys,
                getDetails,
                formatDate
            }
        }
    }
</script>
